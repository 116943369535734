import React from "react";
import "./assets/css/ReturnPolicy.css";

const ReturnPolicy = () => {
    return (
        <div className="return-container">
          <h1>Return Policy</h1>
          <section>
            <p style={{ color: '#333' }}>
              Our Return Policy allows for refunds or exchanges within the first <strong>7 days</strong> from the date of purchase. 
              If more than 7 days have passed, returns, exchanges, or refunds will not be available. 
              Please review the terms below to determine eligibility:
            </p>
            <ol>
              <li>
                <strong>Eligibility Criteria:</strong>
                <ul>
                  <li>The item must be unused and in the same condition as when you received it.</li>
                  <li>The item must have its original packaging intact.</li>
                  <li>
                    Items purchased during a sale are <strong>not eligible</strong> for return or exchange.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Defective or Damaged Items:</strong> Items that are defective or damaged can be replaced upon an approved exchange request.
              </li>
              <li>
                <strong>Exemptions:</strong> Certain product categories are exempt from returns or refunds. These exemptions will be clearly 
                communicated at the time of purchase.
              </li>
              <li>
                <strong>Return and Exchange Process:</strong>
                <ul>
                  <li>
                    After we receive your returned item, it will be inspected. If approved after a quality check, we will process 
                    your return or exchange according to our policies.
                  </li>
                  <li>You will be notified via email regarding the status of your return or exchange request.</li>
                </ul>
              </li>
            </ol>
          </section>
        </div>
      );
};


export default ReturnPolicy;
