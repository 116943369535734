import React from 'react'
import "./assets/css/custom.css"
import languageImage from './assets/images/language.png';
import appIcon from './assets/images/app-icon.png';
import logo from '../../assets/img/logo.png';
import img1 from './assets/images/img1.png';
import img2 from './assets/images/img2.png';
import img3 from './assets/images/img3.png';
import s1 from './assets/images/s1.png';
import s2 from './assets/images/s2.png';
import s3 from './assets/images/s3.png';
import appIconWhite from './assets/images/app-icon-white.png';
import location from './assets/images/location.png';
import rating from './assets/images/rating.png';
import user from './assets/images/user.png';
import faqImg from './assets/images/faq-img.png';
import footLogo from './assets/images/foot-logo.png';
import fb from './assets/images/fb.png';
import tw from './assets/images/tw.png';
import instagram from './assets/images/instagram.png';
import yt from './assets/images/yt.png';
import ins from './assets/images/in.png';


function LandingScreen() {


    const downloadMobileAppFn = () => {
        const link = document.createElement('a');
        link.href = 'https://drive.google.com/uc?export=download&id=1SGWltyyG9Y31PiTByfvm0zf7PztgTyVi';
        link.download = 'DreamChatLive.apk'; // Optional: you can set a default filename for the download
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up by removing the element
        document.body.removeChild(link);
    }



    return (
        <>
            <html lang="en">
                <body>
                    <header className="ldHeader">
                        <nav className="navbar navbar-expand-lg">
                            <div className="container">
                                <a className="navbar-brand" href="#" title="" ><img src={logo} alt="" style={{ height: '57px' }} /></a>
                                <div className="navbar-right">
                                    <a href="/loginAdmin" className="btn btn-primary" style={{ marginRight: '10px' }}>Admin Login</a>
                                    <a className="btn btn-primary" onClick={() => downloadMobileAppFn()}><i><img src={appIcon} alt={''} /></i>Download</a>
                                    <div>
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarMenu">
                                            <ul className="navbar-nav">
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </header>

                    <section className="ldHeroBanner">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 hero-cnt">
                                    <h1>Find Your <span>Perfect Match</span>-Where Real Connections Begin</h1>
                                    <p>Join Dreamchat Live to bring genuine, meaningful relationships to your life.</p>
                                    <a className="btn btn-primary" onClick={() => downloadMobileAppFn()}><i><img src={appIcon} alt='' /></i>Download</a>
                                </div>
                                <div className="col-md-7 text-center hero-img">
                                    <img src={img1} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pt-70 pb-70 lightGray sectionTwo">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 sectionTwoImg">
                                    <img src={img2} alt="" />
                                </div>
                                <div className="col-md-6 sectionTwoCnt">
                                    <h2>Build For Everyone.</h2>
                                    <p style={{ color: 'black' }}>Dreamchat Live is built to foster real conversations and genuine connections. Our unique algorithms prioritize compatibility, ensuring every match has the potential for something real.</p>
                                    {/* <p style={{color:'black'}}>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words.</p> */}
                                    {/* <a href="#" className="btn btn-secondary">Learn more</a> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionThree pt-70 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col pt-70 pb-70 bgIMG">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h2>Connecting Hearts, <span>One Swipe </span> at a Time</h2>
                                            <p>Explore profiles, start conversations, and find someone special.</p>
                                            {/* <a href="#" className="btn btn-outline">Learn more</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionFour pt-70 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center">
                                    <h2>How It Works</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row stepBox">
                                <div className="col-md-5 stepBoxImg">
                                    <img src={s1} alt="" />
                                </div>
                                <div className="col-md-7 stepBoxCnt">
                                    <span>Step. 01</span>
                                    <h3>Found the perfect one</h3>
                                    <p>Browse through profiles and find someone who catches your eye. With our smart matching system, discovering someone special has never been easier!</p>
                                </div>
                            </div>
                            <div className="row stepBox">
                                <div className="col-md-5 stepBoxImg">
                                    <img src={s2} alt="" />
                                </div>
                                <div className="col-md-7 stepBoxCnt">
                                    <span>Step. 02</span>
                                    <h3>Add coins on wallet</h3>
                                    <p>Top up your wallet with coins to unlock premium features that enhance your dating experience. Coins give you access to special perks and tools to make your connections even more memorable.</p>
                                </div>
                            </div>
                            <div className="row stepBox">
                                <div className="col-md-5 stepBoxImg">
                                    <img src={s3} alt="" />
                                </div>
                                <div className="col-md-7 stepBoxCnt">
                                    <span>Step. 03</span>
                                    <h3>Start Dating</h3>
                                    <p>Now that you're ready, start chatting, plan your first date, and see where things go. With Dreamchat Live, finding real connections is fun and easy. Let the journey begin!</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionFive lightGray pt-70 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1 text-center">
                                    <h2>Easily communicate with Dates using The app</h2>
                                    <p style={{ color: 'black' }}>Whether you’re looking for romance, friendship, or something in between, our app is here to connect you with people who share your values and life goals.</p>
                                    <a className="btn btn-secondary" onClick={() => downloadMobileAppFn()}><i><img src={appIconWhite} alt='' /></i>Download</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionSix pt-70 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center">
                                    <h2>The world is small, Find from any where</h2>
                                    <p>With millions of singles worldwide, you’re just a few clicks away from meeting someone special. At Dreamchat Live, we believe in authentic relationships built on mutual interests and meaningful conversations.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col pt-70 text-center locationMap">
                                    <img src={location} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionSeven bgIMG">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 sectionSevenImg">
                                    <img src={img3} alt="" />
                                </div>
                                <div className="col-md-6 sectionSevenCnt">
                                    <h2><span>Get Started</span> Today.</h2>
                                    <p>With verified profiles, secure messaging, and customizable privacy settings, you can focus on getting to know someone special in a safe environment.</p>
                                    <a className="btn btn-primary" onClick={() => downloadMobileAppFn()}><i><img src={appIcon} alt='' /></i>Download</a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionEight pt-70 pb-70">
                        <div className="container">
                            <div className="row">
                                <div className="col text-center">
                                    <h2>Why we are Better</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col darkGray pt-50 pb-50 borR10 js-testimonials">
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1 text-center">
                                            <span className="ratingStar"><img src={rating} /></span>
                                            <h4>It was a great experience</h4>
                                            <p>Joining Dreamchat Live changed my life! I met someone amazing who shares my interests and makes me feel truly valued.</p>
                                            <div className="userBox">
                                                <figure><img src={user} /></figure>
                                                <div>
                                                    <h4>Bishnu Singh</h4>
                                                    <h5>London</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1 text-center">
                                            <span className="ratingStar"><img src={rating} /></span>
                                            {/* <h4>It was a great experience</h4> */}
                                            <p>I’ve tried other dating apps, but this is the only one that made me feel safe and respected. Real profiles, real people – it’s everything I was looking for.</p>
                                            <div className="userBox">
                                                <figure><img src={user} /></figure>
                                                <div>
                                                    <h4>R Kumar</h4>
                                                    <h5>USA</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10 offset-md-1 text-center">
                                            <span className="ratingStar"><img src={rating} /></span>
                                            <p>Trusted brand with a good track record of successful relationships.</p>
                                            <div className="userBox">
                                                <figure><img src={user} /></figure>
                                                <div>
                                                    <h4>Oly Olive</h4>
                                                    <h5>France</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="sectionNine pt-70 pb-70 darkGray">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-8 sectionNineCnt">
                                    <h2>Frequently Asked Questions</h2>
                                    <div className="accordion" id="faq">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    How does the matching system work?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq">
                                                <div className="accordion-body">
                                                    <p>Our matching system considers your profile information, interests, and preferences to suggest compatible profiles. The more you share, the better your matches!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Is my personal information safe?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq">
                                                <div className="accordion-body">
                                                    <p>Yes, we prioritize your privacy. All personal information is protected with top-notch security, and you can adjust privacy settings to control who sees your profile.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    How do I add coins to my wallet?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq">
                                                <div className="accordion-body">
                                                    <p style={{ color: 'black' }}>Go to your wallet in the app, choose a coin package, and complete the transaction using one of our secure payment methods.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Can I use the app for free?
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faq">
                                                <div className="accordion-body">
                                                    <p>Yes! You can use the app for free and access basic features. Coins allow you to unlock premium options to enhance your experience.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 sectionNineImg text-end">
                                    <img src={faqImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="ldFooter">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="footerTop">
                                        <a href="#"><img src={logo} alt="" /></a>
                                        <ul>
                                            <li>Follow us on:</li>
                                            <li><a href="#" target="_blank" title=""><img src={fb} alt="" /></a></li>
                                            <li><a href="#" target="_blank" title=""><img src={tw} alt="" /></a></li>
                                            <li><a href="#" target="_blank" title=""><img src={instagram} alt="" /></a></li>
                                            <li><a href="#" target="_blank" title=""><img src={yt} alt="" /></a></li>
                                            <li><a href="#" target="_blank" title=""><img src={ins} alt="" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center footerBot">
                                <div className="col-md-6 footerBotLft">
                                    <h2>Stay Connected with us.</h2>
                                    <p>Join our community and connect with others who are also on the journey to finding meaningful connections.</p>
                                </div>
                                <div className="col-md-6 footerBotRht">
                                    <form>
                                        <div className="subsCription">
                                            <input type="email" className="form-control" placeholder="Enter your email address" />
                                            <button className="btn btn-primary">Subscribe Now</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="col copyright text-center">
                                    <p>© Copyright Dream Chat Live 2024.</p>
                                </div> */}
                                <div className="col text-center">
                                    <ul className="footerLinks">
                                        <li><a href="/terms-and-conditions" target="_blank">Terms and Conditions</a></li>
                                        <li><a href="/refund-policy" target="_blank">Refund Policy</a></li>
                                        <li><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
                                        <li><a href="/return-policy" target="_blank">Return Policy</a></li>
                                        <li><a href="/contact-us" target="_blank">Contact Us</a></li>
                                    </ul>
                                    <p>© Copyright Dream Chat Live 2024.</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
                    <script src="assets/js/custom.js"></script>
                </body>
            </html>
        </>
    )
}

export default LandingScreen;