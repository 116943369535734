import React, { useState } from "react";
import "./assets/css/ContactUs.css"; // Add this CSS file for styling

function ContactUs() {
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Thank you for contacting us!");
    setFormData({
      fullName: "",
      company: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    });
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-left">
        <h2>Contact Us</h2>
        <p>
          Not sure what you need? The team at DreamChatLive will be happy to
          listen to you and suggest solutions you may not have considered.
        </p>
        <p>Email: <a href="mailto:support@dreamchatlive.com">support@dreamchatlive.com</a></p>
      </div>
      <div className="contact-us-right">
        <h2>We'd love to hear from you! Let's get in touch</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="company"
              placeholder="Company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
